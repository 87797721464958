<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <Loader v-if="!group" />
      <div class="dashboard__container--header" v-if="group">
        <div>
          <h1 v-if="group.visible">{{group.title}} Job Posting</h1>
          <h1 v-if="!group.visible">{{group.title}} Labor Pool</h1>
          <span class="flex mb-3 flex-wrap">
            
            <router-link :to="{name: 'group'}">
              <button class="btn btn__small" v-bind:class="{ 'btn__dark': isMembers, 'btn__outlined': !isMembers }">Members</button>
            </router-link>
            <router-link :to="{name: 'groupApplicants'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isApplicants, 'btn__outlined': !isApplicants }">New Applications</button>
            </router-link>
            <router-link :to="{name: 'groupWaitlist'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isWaitlist, 'btn__outlined': !isWaitlist }">Waitlist</button>
            </router-link>
            
            <router-link :to="{name: 'groupMessages'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isMessages, 'btn__outlined': !isMessages }" v-if="isOwner || isUser || isAdmin">Messages</button>
            </router-link>
            <router-link :to="{name: 'groupEdit'}">
              <button class="btn btn__small ml-3" v-bind:class="{ 'btn__dark': isEdit, 'btn__outlined': !isEdit }" v-if="isOwner || isUser || isAdmin">Settings</button>
            </router-link>
            <a :href="`https://jumpstaffing.com/groupform/` + $route.params.id" target="_blank">
              <button class="btn btn__small btn__outlined ml-3 mb-2">Web Form</button>
            </a>
          </span>
        </div>
        <span class="flex align-center">
          <button v-if="!isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="addWatch()">Watchlist <i class="fa-regular fa-star ml-2"></i></button>
           <button v-if="isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeWatch()">Remove <i class="fa-solid fa-star warning ml-2"></i></button>
          <button class="btn btn__outlined btn__small mb-3" @click="goBack"><i class="fas fa-arrow-left"></i></button>
        </span>
        
      </div>
      <router-view :key="$route.params.id" :group="group" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'groupHome',
  computed: {
    ...mapState(['group', 'currentUser', 'userProfile']),
    // client() {
    //   return this.clientInfo
    // },
    theGroup() {
      return this.group
    },
    // clean() {
    //   return (!this.groupUsers || this.groupUsers.length == 0)
    // },
    // sortedUsers() {
    //   if (this.groupUsers && this.groupUsers.length >= 1) {
    //     return this.groupUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))
    //   } else {
    //     return []
    //   }
      // return this.groupUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))
        // return ((a.firstName && a.firstName.toLowerCase()) - (b.firstName && b.firstName.toLowerCase()));
      // })
    // },
    // isContacts() {
    //   return this.$route.name == 'clientContacts';
    // },
    isEdit() {
      return this.$route.name == 'groupEdit';
    },
    // isKaela() {
    //   return this.currentUser.uid == 'YjqnBY7AbUZuZWcinHab9TNiH9n1'
    // },
    isMessages() {
      return this.$route.name == 'groupMessages';
    },
    isWaitlist() {
      return this.$route.name == 'groupWaitlist';
    },
    isApplicants() {
      return this.$route.name == 'groupApplicants';
    },
    isMembers() {
      return this.$route.name == 'group';
    },
    isOwner() {
      return this.group.owner == this.currentUser.uid
    },
    isAdmin() {
      // if (this.group && this.group.admins) {
      //   return (this.group.admins.some(person => person.userId == this.currentUser.uid) || this.currentUser.uid == 'DN9vVu3qDhPdpBOUpIb8GopOuhY2')
      // }
      return true
    },
    isUser() {
      if (this.group && this.group.users) {
        return this.group.users.some(person => person.userId == this.currentUser.uid)
      }
    },
    isWatchList() {
      if (this.userProfile && this.userProfile.groupWatchList && this.userProfile.groupWatchList.includes(this.group.id)) {
        return true
      } else {
        return false
      }
    },
  },
  created () {
    this.$store.dispatch("getGroupFromId", this.$route.params.id);
  },
  components: {
    Loader,
  },

  methods: {
    addWatch() {
      this.$store.dispatch('watchGroup', this.$route.params.id)
    },
    removeWatch() {
      this.$store.dispatch('unWatchGroup', this.$route.params.id)
    },
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    console.log(this)
  }
}
</script>